import { createFileRoute, Navigate, redirect } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { useLocation } from "@tanstack/react-router";

export const Route = createFileRoute("/(main)/spotify/$")({
	component: () => {
		window.location.href =
			"https://open.spotify.com/show/1r6rQDK6zSx7c3fGaYO8Of";
		return null;
	},
	// redirect: {
	// 	to: "/startgreen-by-iptic",
	// },
	// loader: () => {
	// 	redirect({
	// 		to: "/startgreen-by-iptic",
	// 		throw: true,
	// 	});
	// },
});
