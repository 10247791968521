import { createFileRoute, Navigate, redirect } from "@tanstack/react-router";
import { useLocation } from "@tanstack/react-router";

export const Route = createFileRoute("/(main)/spotify/")({
	component: () => {
		window.location.href =
			"https://open.spotify.com/show/1r6rQDK6zSx7c3fGaYO8Of";
		return null;
	},
	// redirect: {
	// 	to: "/startgreen-by-iptic",
	// },
	// loader: () => {
	// 	const location = useLocation();
	// 	const newPath = location.pathname.replace(
	// 		"/startgreen",
	// 		"/startgreen-by-iptic"
	// 	);
	// 	redirect({
	// 		to: newPath,
	// 		throw: true,
	// 	});
	// },
});
