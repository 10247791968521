import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import Meta from "@components/layout/common/Meta.tsx";
import Application from "@pages/(startgreen)/views/(form)/Application.tsx";
import { ConfirmPageStartgreen } from "@pages/(startgreen)/confirm";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");
	return (
		<>
			<Meta
				title={"StartGreen by IPTIC"}
				template={"%s | Coachini"}
				description={tMeta("about-page.description")}
				keywords={["startgreen", "start-green", "STARTGREEN", "#STARTGREEN"]}
				robots="index,follow"
				ogTitle={tMeta("about-page.title")}
				ogDescription={tMeta("about-page.description")}
				className={"bg-[#F3F3F3]"}
			/>

			<ConfirmPageStartgreen />
		</>
	);
};

export const Route = createFileRoute(
	"/(main)/startgreen-by-iptic/confirm/$cid"
)({
	component: Page,
});
