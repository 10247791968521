import { Accordion } from "@pages/(startgreen)/views/(participants)/Accordion.tsx";
import { cn } from "@core/utils";

import { useQuery } from "@tanstack/react-query";
import { getPhases } from "@api/service/startgreen.services.ts";
import Spinner from "@components/elements/Spinner";
import React, { useState } from "react";
import {
	educationLevelOptions,
	genderOptions,
	professionalSituationOptions,
	regionOptions,
	discoverySourceOptions,
	hackathonParticipationOptions,
	previousSupportOptions,
	projectAreaOptions,
	projectDevelopmentStageOptions,
	supportNeedsOptions1,
	supportThemesOptions,
	presenceConfirmationOptions,
	groupOptions,
} from "@pages/(startgreen)/views/(form)/FormStructure.ts";
import { Tooltip } from "@pages/(startgreen)/views/(dashboard)/Tooltip.tsx";

const FilterSection = ({ filterState, resetFilters, handleFilterChange }) => {
	const [localPhase, setLocalPhase] = useState(filterState.selectedPhase || "");
	const [localMinDate, setLocalMinDate] = useState(
		filterState.selectedMinDate || ""
	);
	const [localMaxDate, setLocalMaxDate] = useState(
		filterState.selectedMaxDate || ""
	);

	const {
		data: phases,
		isLoading,
		error,
		refetch,
	} = useQuery({
		queryKey: ["phases"],
		queryFn: getPhases,
	});

	const formatDateString = (dateString) => {
		if (!dateString) return "";
		return dateString.split("T")[0];
	};

	if (isLoading)
		return (
			<div className={"flex items-center justify-center"}>
				<Spinner />
			</div>
		);

	return (
		<div className="w-64 bg-white p-4 rounded-lg border-2 border-startgreen_darkblue-100/30 drop-shadow-md">
			<div className={"flex flex-row justify-between items-center mb-4"}>
				<h2 className="text-xl font-semibold">Filtres</h2>

				<svg
					onClick={resetFilters}
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth={1.5}
					stroke="currentColor"
					className="size-6 cursor-pointer"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
					/>
				</svg>
			</div>

			<div className="flex flex-col">
				<div className={"flex gap-2 items-center mb-2"}>
					<h3 className="font-semibold  ">Recherche</h3>
					<Tooltip
						title="Fonction de recherche"
						description="Recherchez par (nom du candidat, région, domaine du projet, stade de développement, besoins du support, situation professionnelle et niveau d'éducation)"
					/>
				</div>

				<input
					type="text"
					placeholder="Recherche"
					value={filterState.searchQuery}
					onChange={(e) => {
						const searchQueries = e.target.value.split(";");
						// .map((query) => query.trim());
						handleFilterChange("searchQuery", searchQueries);
					}}
					className={cn(
						"border border-gray-300 p-2 mb-4 rounded-md",
						filterState.searchQuery.length > 0 &&
							"bg-startgreen_tertiary-100/10 border-startgreen_tertiary-100/50"
					)}
				/>
			</div>
			<Accordion
				title="Région"
				options={regionOptions}
				selectedOptions={filterState.selectedRegion}
				onFilterChange={(option) =>
					handleFilterChange("selectedRegion", option)
				}
			/>
			<Accordion
				title="Genre"
				options={genderOptions.map((option) => option)}
				selectedOptions={filterState.selectedGender}
				onFilterChange={(option) =>
					handleFilterChange("selectedGender", option)
				}
			/>
			{/*#region AGE Filter*/}
			<div className="mb-4 flex flex-col w-full justify-center">
				<h3 className="font-semibold mb-2">Âge</h3>
				<div
					className={cn(
						"flex flex-col space-y-2  border rounded-md px-2 py-4",
						filterState.selectedMinAge || filterState.selectedMaxAge
							? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
							: "border-gray-300"
					)}
				>
					<label className="flex items-center justify-between">
						<span className="font-medium text-gray-700">Min :</span>
						<input
							className={cn(
								"ml-2 border rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-startgreen_primary-100/20",
								filterState.selectedMinAge
									? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
									: "border-gray-300"
							)}
							type="number"
							min="14"
							max={filterState.selectedMaxAge}
							value={filterState.selectedMinAge}
							onChange={(e) =>
								handleFilterChange("selectedMinAge", +e.target.value)
							}
						/>
					</label>

					<label className="flex items-center justify-between">
						<span className="font-medium text-gray-700">Max :</span>
						<input
							className={cn(
								"ml-2 border rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-startgreen_primary-100/20",
								filterState.selectedMinAge
									? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
									: "border-gray-300"
							)}
							type="number"
							min={filterState.selectedMinAge}
							max="120"
							value={filterState.selectedMaxAge}
							onChange={(e) =>
								handleFilterChange("selectedMaxAge", +e.target.value)
							}
						/>
					</label>
				</div>
			</div>
			{/*#endregion*/}

			<Accordion
				title="Stade de développement"
				options={projectDevelopmentStageOptions}
				selectedOptions={filterState.selectedDevelopmentStage}
				onFilterChange={(option) =>
					handleFilterChange("selectedDevelopmentStage", option)
				}
			/>

			<Accordion
				title="L'idée du projet"
				options={projectAreaOptions}
				selectedOptions={filterState.selectedProjectFocus}
				onFilterChange={(option) =>
					handleFilterChange("selectedProjectFocus", option)
				}
			/>

			<Accordion
				title="Besoins de soutien"
				options={supportNeedsOptions1}
				selectedOptions={filterState.selectedSupportNeeds}
				onFilterChange={(option) =>
					handleFilterChange("selectedSupportNeeds", option)
				}
			/>

			<Accordion
				title="Thèmes de support"
				options={supportThemesOptions}
				selectedOptions={filterState.selectedSupportThemes}
				onFilterChange={(option) =>
					handleFilterChange("selectedSupportThemes", option)
				}
			/>

			{/*#region Employee Filter*/}
			<div className="mb-4 flex flex-col w-full justify-center">
				<h3 className="font-semibold mb-2">Nombres d'employés</h3>
				<div
					className={cn(
						"flex flex-col space-y-2  border rounded-md px-2 py-4",
						filterState.selectedMinEmployeesCount ||
							filterState.selectedMaxEmployeesCount
							? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
							: "border-gray-300"
					)}
				>
					<label className="flex items-center justify-between">
						<span className="font-medium text-gray-700">Min :</span>
						<input
							className={cn(
								"ml-2 border rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-startgreen_primary-100/20",
								filterState.selectedMinEmployeesCount
									? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
									: "border-gray-300"
							)}
							type="number"
							min="0"
							max={filterState.selectedMaxEmployeesCount}
							value={filterState.selectedMinEmployeesCount}
							onChange={(e) =>
								handleFilterChange("selectedMinEmployeesCount", +e.target.value)
							}
						/>
					</label>

					<label className="flex items-center justify-between">
						<span className="font-medium text-gray-700">Max :</span>
						<input
							className={cn(
								"ml-2 border rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-startgreen_primary-100/20",
								filterState.selectedMaxEmployeesCount
									? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
									: "border-gray-300"
							)}
							type="number"
							min={filterState.selectedMinEmployeesCount}
							max="120"
							value={filterState.selectedMaxEmployeesCount}
							onChange={(e) =>
								handleFilterChange("selectedMaxEmployeesCount", +e.target.value)
							}
						/>
					</label>
				</div>
			</div>
			{/*#endregion*/}

			{/*#region Sales Revenue Filter*/}
			<div className="mb-4 flex flex-col w-full justify-center">
				<h3 className="font-semibold mb-2">Revenus</h3>
				<div
					className={cn(
						"flex flex-col space-y-2  border rounded-md px-2 py-4",
						filterState.selectedMinSalesRevenue ||
							filterState.selectedMaxSalesRevenue
							? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
							: "border-gray-300"
					)}
				>
					<label className="flex items-center justify-between">
						<span className="font-medium text-gray-700">Min :</span>
						<input
							className={cn(
								"ml-2 border rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-startgreen_primary-100/20",
								filterState.selectedMinSalesRevenue
									? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
									: "border-gray-300"
							)}
							type="number"
							min="14"
							max={filterState.selectedMaxSalesRevenue}
							value={filterState.selectedMinSalesRevenue}
							onChange={(e) =>
								handleFilterChange("selectedMinSalesRevenue", +e.target.value)
							}
						/>
					</label>

					<label className="flex items-center justify-between">
						<span className="font-medium text-gray-700">Max :</span>
						<input
							className={cn(
								"ml-2 border rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-startgreen_primary-100/20",
								filterState.selectedMaxSalesRevenue
									? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
									: "border-gray-300"
							)}
							type="number"
							min={filterState.selectedMinSalesRevenue}
							max="120"
							value={filterState.selectedMaxSalesRevenue}
							onChange={(e) =>
								handleFilterChange("selectedMaxSalesRevenue", +e.target.value)
							}
						/>
					</label>
				</div>
			</div>
			{/*#endregion*/}

			<Accordion
				title="Cohorte"
				options={groupOptions}
				selectedOptions={filterState.selectedGroup}
				onFilterChange={(option) => handleFilterChange("selectedGroup", option)}
			/>

			<Accordion
				title="Présence Confirmée"
				options={presenceConfirmationOptions}
				selectedOptions={filterState.selectedPresenceConfirmed}
				onFilterChange={(option) =>
					handleFilterChange("selectedPresenceConfirmed", option)
				}
			/>

			<Accordion
				title="Participation Hackathon"
				options={hackathonParticipationOptions}
				selectedOptions={filterState.selectedHackathonParticipation}
				onFilterChange={(option) =>
					handleFilterChange("selectedHackathonParticipation", option)
				}
			/>

			<Accordion
				title="Soutien antérieur"
				options={previousSupportOptions}
				selectedOptions={filterState.selectedPreviousSupport}
				onFilterChange={(option) =>
					handleFilterChange("selectedPreviousSupport", option)
				}
			/>
			<Accordion
				title="Source de découverte"
				options={discoverySourceOptions}
				selectedOptions={filterState.selectedDiscoverySource}
				onFilterChange={(option) =>
					handleFilterChange("selectedDiscoverySource", option)
				}
			/>

			<Accordion
				title="Situation professionnelle"
				options={professionalSituationOptions}
				selectedOptions={filterState.selectedProfessionalStatus}
				onFilterChange={(option) =>
					handleFilterChange("selectedProfessionalStatus", option)
				}
			/>

			<Accordion
				title="Niveau d'éducation sélectionné"
				options={educationLevelOptions}
				selectedOptions={filterState.selectedEducationLevel}
				onFilterChange={(option) =>
					handleFilterChange("selectedEducationLevel", option)
				}
			/>

			{/* #region Date of Submission Section */}
			<div className="mb-4 flex flex-col w-full justify-center">
				<h3 className="font-semibold mb-2">Date de soumission</h3>
				<div
					className={cn(
						"flex flex-col space-y-2 border rounded-md px-2 py-4",
						filterState.selectedMinDate || filterState.selectedMaxDate
							? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
							: "border-gray-300"
					)}
				>
					{/*DATE OF SUBMISSION*/}
					{/*---------------------------------------*/}
					<div className="mb-4 flex flex-col w-full justify-center">
						<Tooltip
							title="Filtrer par date d'inscription"
							description="Filtrez par date d'inscription du formulaire. Vous pouvez utiliser les dates de phase comme modèle pour vous aider."
						/>

						<h3 className="font-semibold mb-2">Sélectionner une phase</h3>
						<select
							value={localPhase}
							onChange={(e) => {
								const selectedPhase =
									phases.find((phase) => phase.id === e.target.value) || null;
								setLocalPhase(e.target.value);

								if (selectedPhase) {
									const formattedMinDate = formatDateString(
										selectedPhase.startDate
									);
									const formattedMaxDate = formatDateString(
										selectedPhase.endDate
									);
									setLocalMinDate(formattedMinDate);
									setLocalMaxDate(formattedMaxDate);

									handleFilterChange("selectedMinDate", formattedMinDate);
									handleFilterChange("selectedMaxDate", formattedMaxDate);
								} else {
									setLocalMinDate("");
									setLocalMaxDate("");
									handleFilterChange("selectedMinDate", "");
									handleFilterChange("selectedMaxDate", "");
								}
							}}
							className={cn(
								"border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-startgreen_primary-100/20",
								localPhase
									? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
									: "border-gray-300"
							)}
						>
							<option value="">Sélectionner une phase</option>
							{phases &&
								phases.map((phase) => (
									<option key={phase.id} value={phase.id}>
										{phase.name}
									</option>
								))}
						</select>
					</div>
					{/*---------------------------------------*/}
					<label className="flex flex-col items-start justify-between">
						<span className="font-medium text-gray-700">Min :</span>
						<input
							type="date"
							value={formatDateString(filterState.selectedMinDate)}
							onChange={(e) =>
								handleFilterChange("selectedMinDate", e.target.value)
							}
							className={cn(
								"w-full border rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-startgreen_primary-100/20",
								filterState.selectedMinDate
									? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
									: "border-gray-300"
							)}
						/>
					</label>

					<label className="flex flex-col items-start justify-between">
						<span className="font-medium text-gray-700">Max :</span>
						<input
							type="date"
							value={formatDateString(filterState.selectedMaxDate)}
							onChange={(e) =>
								handleFilterChange("selectedMaxDate", e.target.value)
							}
							className={cn(
								"w-full border rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-startgreen_primary-100/20",
								filterState.selectedMaxDate
									? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
									: "border-gray-300"
							)}
						/>
					</label>
				</div>
			</div>
			{/* #endregion	-------- */}
		</div>
	);
};
export default FilterSection;
