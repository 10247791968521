import axios from "axios";
import { appConfig } from "@config/appConfig.ts";

export const getPhases = async () => {
	const response = await axios.get(
		`${appConfig.startGreenUrl}/platform/phases/all`
	);
	return response.data;
};
export const fetchCurrentPhase = async () => {
	const url = `${appConfig.startGreenUrl}/platform/phases/current`;
	const response = await axios.get(url);
	return response.data;
};
export const togglePhaseStatus = async (id: string, isOpen: boolean) => {
	await axios.patch(`${appConfig.startGreenUrl}/platform/phases/${id}/toggle`, {
		isOpen,
	});
};

export const updateParticipation = async (
	id: string,
	canApply: "USER" | "VISITOR" | "NONE"
) => {
	await axios.patch(
		`${appConfig.startGreenUrl}/platform/phases/${id}/participation`,
		{ canApply }
	);
};

export const updatePhase = (id: string, data: any) =>
	axios.patch(`${appConfig.startGreenUrl}/platform/phases/${id}`, data);

// --------------------------------------- //

export const getStatus = () => axios.get(`${appConfig.startGreenUrl}/status`);
export const updateStatus = (id: string, data: any) =>
	axios.patch(`${appConfig.startGreenUrl}/status/${id}`, data);

export const getPlatformStatus = async () => {
	const response = await axios.get(`${appConfig.startGreenUrl}/status`);
	return response.data;
};

export const updatePlatformStatus = async (
	id: string,
	openStatus: boolean,
	applyStatus: boolean,
	message?: string,
	passcode?: string
) => {
	await axios.patch(`${appConfig.startGreenUrl}/status/${id}`, {
		openStatus,
		applyStatus,
		message,
		passcode,
	});
};

export const getGlobalMessage = async () => {
	const response = await axios.get(
		`${appConfig.startGreenUrl}/platform/global-messages/current`
	);
	return response.data;
};

export const createGlobalMessage = async (
	message: string,
	display: boolean
) => {
	await axios.post(`${appConfig.startGreenUrl}/platform/global-messages`, {
		message,
		display,
	});
};

export const updateGlobalMessage = (
	id: string,
	display: boolean,
	message: string
) =>
	axios.patch(`${appConfig.startGreenUrl}/platform/global-messages/${id}`, {
		display,
		message,
	});

export const deleteGlobalMessage = async (id: string) => {
	await axios.delete(
		`${appConfig.startGreenUrl}/platform/global-messages/${id}`
	);
};
//+++++++++++++++++++++++++++++++++
export const sendOtp = async (email: string, name: string) => {
	return axios.post(`${appConfig.startGreenUrl}/submissions/send-otp`, {
		email,
		name,
	});
};

export const verifyOtp = async (email: string, otp: string) => {
	return axios.post(`${appConfig.startGreenUrl}/submissions/verify-otp`, {
		email,
		otp,
	});
};
// +++++++++++++++++++++++++++++++++++

export const submitForm = async (submissionData: any) => {
	return axios.post(`${appConfig.startGreenUrl}/submissions`, submissionData);
};

// +++++++++++++++++++++++++++++++++
export const validateBulkSubmissions = async (ids: string[]) => {
	return axios.post(`${appConfig.startGreenUrl}/submissions/validate/bulk`, {
		ids,
	});
};

export const declineBulkSubmissions = async (ids: string[]) => {
	return axios.post(`${appConfig.startGreenUrl}/submissions/decline/bulk`, {
		ids,
	});
};
export const groupSub = async (ids: string[] | any, group: string) => {
	return axios.post(`${appConfig.startGreenUrl}/submissions/group`, {
		ids,
		group,
	});
};
export const validateSubmission = async (id: string) => {
	return axios.post(`${appConfig.startGreenUrl}/submissions/validate`, {
		id,
	});
};

export const declineSubmission = async (id: string) => {
	return axios.post(`${appConfig.startGreenUrl}/submissions/decline`, {
		id,
	});
};

export const getAllSubmissions = async () => {
	const response = await axios.get(
		`${appConfig.startGreenUrl}/submissions/all`
	);
	return response.data;
};

export const fetchSubmissions = async (status, type) => {
	// Prepare the URL
	let url = `${appConfig.startGreenUrl}/submissions/all`;

	if (status) {
		url += `?status=${status}`;
	}

	if (type) {
		url += `&type=${type}`;
	}
	const response = await axios.get(url);
	return response.data;
};
export const fetchStatistics = async () => {
	const url = `${appConfig.startGreenUrl}/submissions/all/statistics`;
	const response = await axios.get(url);
	return response.data;
};

export const fetchSubmissionById = async (id: string) => {
	const response = await axios.get(
		`${appConfig.startGreenUrl}/submissions/${id}`
	);
	return response.data;
};

export const fetchSubmissionByMail = async (email: string) => {
	const response = await axios.get(
		`${appConfig.startGreenUrl}/submissions/email/${email}`
	);
	return response.data;
};

//---------------------------------//
export const fetchTemplates = async () => {
	const response = await axios.get(
		`${appConfig.startGreenUrl}/mail/get-templates`
	);
	return response.data;
};

export const createTemplate = async ({
	name,
	template,
	dynamicFields,
}: {
	name: string;
	template: string;
	dynamicFields: string[];
}) => {
	return axios.post(`${appConfig.startGreenUrl}/mail/save-template`, {
		name,
		template,
		dynamicFields,
	});
};

export const updateTemplate = async ({
	id,
	name,
	subject,
	template,
	dynamicFields,
}: {
	id: string;
	name: string;
	subject: string;

	template: string;
	dynamicFields: string[];
}) => {
	return axios.patch(`${appConfig.startGreenUrl}/mail/update-template/${id}`, {
		name,
		subject,
		template,
		dynamicFields,
	});
};

export const deleteTemplate = async (id: string) => {
	return axios.delete(`${appConfig.startGreenUrl}/mail/delete-template/${id}`);
};

export const fetchScheduledMails = async () => {
	const response = await axios.get(
		`${appConfig.startGreenUrl}/mail/get-scheduled-emails`
	);
	return response.data;
};

export const cancelScheduledMail = async (id: string) => {
	return axios.delete(`${appConfig.startGreenUrl}/mail/schedule/${id}`);
};

export const cancelAllScheduledMail = async () => {
	return axios.delete(`${appConfig.startGreenUrl}/mail/schedule/cancel/all`);
};
//------------------------------------//

export const fetchVisitors = async () => {
	const response = await axios.get(`${appConfig.startGreenUrl}/visitors/all`);
	return response.data;
};

export const createVisitors = async ({ emails }: { emails: string[] }) => {
	// Create an array of CreateVisitorDto objects
	const visitorsDto = emails.map((email) => ({
		email, // Directly set the email
		isConfirmed: false, // Default value if needed
		phase: undefined, // Optional field
		invitedBy: undefined, // Optional field
	}));

	return axios.post(`${appConfig.startGreenUrl}/visitors/bulk`, visitorsDto);
};

export const uploadVisitors = async (file) => {
	const formData = new FormData();
	formData.append("file", file);

	return axios.post(`${appConfig.startGreenUrl}/visitors/upload`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

//------------------
export const exportData = async ({ status, format, email }) => {
	try {
		const response = await axios.get(
			`${appConfig.startGreenUrl}/submissions/export/by-status`,
			{
				params: {
					status,
					format,
					email,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Export error:", error);
		throw new Error("Error exporting data");
	}
};

export const decryptQrBadge = async (badge: string) => {
	const response = await axios.post(
		`${appConfig.startGreenUrl}/qrcode/decrypt/${badge}`
	);
	return response.data;
};
