import type { FunctionComponent } from "types/types.ts";
import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import Navbar from "@pages/(startgreen)/views/(landing)/Navbar.tsx";
import { Link, useParams } from "@tanstack/react-router";
import axios from "axios";
import { appConfig } from "@config/appConfig.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import Text from "@components/elements/Text";
import { CircularProgress } from "@mui/material"; // Importing Material UI spinner
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Importing the blue checkmark icon

export const ConfirmPageStartgreen = (): FunctionComponent => {
	const { t } = useTranslation("coaches-page");
	const { cid } = useParams({ strict: false });
	const queryClient = useQueryClient();
	const [processing, setProcessing] = useState(false);

	const confirmPresence = useMutation({
		mutationFn: () => {
			setProcessing(true);
			return axios.post(
				`${appConfig.startGreenUrl}/submissions/confirm-presence/`,
				{
					mail: cid,
				}
			);
		},
		onSuccess: () => {
			void queryClient.invalidateQueries();
			setProcessing(false);
		},
		onError: () => {
			setProcessing(false);
		},
	});

	useEffect(() => {
		if (cid) {
			confirmPresence.mutate();
		}
	}, [cid]); // Trigger only when `cid` is available

	return (
		<main
			className={cn(
				"flex flex-col h-full w-full",
				"pt-[40px] pb-[120px] px-[20px] md:px-[163px] mx-auto max-w-screen-_middle",
				"gap-8"
			)}
		>
			<Navbar />

			<section
				className={cn(
					"relative flex w-full bg-startgreen_lightcream-100 h-full",
					"flex flex-col items-center justify-center",
					"rounded-md p-5 shadow-xSm",
					"gap-5 lg:flex-row md:gap-5"
				)}
			>
				{/*--------------------------------------------*/}
				<div
					className={cn(
						"flex flex-col justify-center items-center w-full gap-[30px]",
						"px-0 md:px-0 lg:px-24"
					)}
				>
					<div
						className={"flex flex-col items-center justify-center gap-[10px]"}
					>
						{processing && (
							<div className="flex flex-col justify-center items-center gap-4">
								<CircularProgress size={70} color="primary" />{" "}
								{/* Blue spinner */}
								<span className={"text-titleMedium _tablet:text-titleLarge"}>
									Nous confirmons votre présence
								</span>{" "}
								{/* Text in French */}
							</div>
						)}

						{/* Show success message after confirmation */}
						{!processing && (
							<div className="flex flex-col justify-center items-center gap-2">
								<CheckCircleIcon color="primary" fontSize="large" />{" "}
								{/* Blue checkmark icon */}
								<span className={"text-titleMedium _tablet:text-titleLarge"}>
									Merci pour votre confirmation
								</span>{" "}
								<Link to={"/startgreen-by-iptic"}>
									<button
										type="submit"
										className="bg-iptic_primary-100 hover:bg-iptic_primary-100/80 text-white px-6 py-3 rounded-md mt-4"
									>
										Retour à l'accueil
									</button>
								</Link>
							</div>
						)}
					</div>
					{/*---------------------*/}
				</div>
				{/*--------------------------------------------*/}
			</section>
		</main>
	);
};
