import React, { useState } from "react";
import { Form, Formik } from "formik";
import FormField from "./FormField";
import { step2ValidationSchema } from "@pages/(startgreen)/views/(form)/validationSchema.ts";
import useResetFieldOnCondition from "@pages/(startgreen)/views/(form)/useRestFieldOnCondition.ts";
import { sendOtp } from "@api/service/startgreen.services.ts";
import { RadioGroup } from "@pages/(startgreen)/views/(form)/FormGroups.tsx";

interface Step2Props {
	nextStep: () => void;
	prevStep: () => void;
	formData: any;
	setFormData: (values: any) => void;
}

const Step2: React.FC<Step2Props> = ({
	nextStep,
	prevStep,
	formData,
	setFormData,
}) => {
	const [loading, setLoading] = useState(false);

	const handleFormSubmit = async (values: any) => {
		try {
			setLoading(true);
			setFormData(values);
			await sendOtp(values.email, values.fullName);
			nextStep();
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Formik
			initialValues={formData}
			validationSchema={step2ValidationSchema}
			onSubmit={handleFormSubmit}
		>
			{({ values, handleSubmit, setFieldValue }) => {
				// useResetFieldOnCondition(
				// 	values.education_level?.includes("education_other"),
				// 	"education_other_specify",
				// 	setFieldValue,
				// 	[values.education_level, setFieldValue]
				// );
				return (
					<Form onSubmit={handleSubmit}>
						<FormHeader title="Informations personnelles" />
						<FormContent values={values} />
						<FormButtons prevStep={prevStep} loading={loading} />
					</Form>
				);
			}}
		</Formik>
	);
};

const FormHeader = ({ title }: { title: string }) => (
	<div className="flex flex-col mb-[30px]">
		<h2 className="text-[24px] font-bold text-startgreen_primary-100">
			{title}
		</h2>
	</div>
);

const FormContent = ({ values }: { values: any }) => (
	<div className="flex flex-col gap-[30px]">
		<TextInputGroup title="Nom et Prénom :" name="fullName" type="text" />
		<TextInputGroup title="Email :" name="email" type="email" />
		<TextInputGroup
			title="Numero de téléphone :"
			name="phoneNumber"
			type="text"
		/>
		<SelectGroup
			title="Ma région est :"
			name="region"
			options={regionOptions}
		/>
		<RadioGroup title="Sexe :" name="gender" options={genderOptions} />
		<TextInputGroup title="Âge :" name="age" type="number" />
		<RadioGroup
			title="Situation professionnelle actuelle :"
			name="professional_situation"
			options={professionalSituationOptions}
		/>
		<RadioGroup
			title="Niveau d'études actuel ou le plus élevé :"
			name="education_level"
			options={educationLevelOptions}
			conditionalField={{
				condition: values.education_level?.includes("education_other"),
				name: "education_other_specify",
				label: "Précisez",
				placeholder: "Précisez...",
			}}
		/>
	</div>
);

export const TextInputGroup = ({
	title,
	name,
	type,
}: {
	title: string;
	name: string;
	type: string;
}) => (
	<div className="bg-white rounded-md p-4">
		<h3>{title}</h3>
		<div className="p-2 text-[16px] text-text-black">
			<FormField name={name} type={type} />
		</div>
	</div>
);

export const SelectGroup = ({
	title,
	name,
	options,
}: {
	title: string;
	name: string;
	options: any[];
}) => (
	<div className="bg-white rounded-md p-4">
		<h3>{title}</h3>
		<div className="p-2 text-[16px] text-text-black">
			<FormField name={name} type="select" options={options} />
		</div>
	</div>
);

const FormButtons = ({
	prevStep,
	loading,
}: {
	prevStep: () => void;
	loading: boolean;
}) => (
	<div className="flex items-center justify-between">
		<button
			type="button"
			onClick={prevStep}
			className="bg-startgreen_primary-100/20 hover:bg-startgreen_primary-100/30 text-startgreen_primary-100 px-6 py-3 rounded-md mt-4"
		>
			Précédent
		</button>
		<button
			type="submit"
			disabled={loading}
			className={`${
				loading
					? "bg-startgreen_primary-100/50 cursor-not-allowed"
					: "bg-startgreen_primary-100 hover:bg-startgreen_primary-100/90"
			} text-white px-6 py-3 rounded-md mt-4`}
		>
			{loading ? "En cours..." : "Suivant"}
		</button>
	</div>
);
export const regionOptions = [
	{ label: "Sélectionner", value: "" },
	{ label: "Bouficha", value: "bouficha" },
	{ label: "Enfidha", value: "enfidha" },
	{ label: "Hergla", value: "hergla" },
	{ label: "Sidi Bou Ali", value: "sidi_bou_ali" },
	{ label: "Sidi El Heni", value: "sidi_el_heni" },
	{ label: "Kondar", value: "kondar" },
	{ label: "Kalla kbira", value: "kalla_kbira" },
	{ label: "Kalla sghira", value: "kalla_sghira" },
	{ label: "Hamam Sousse", value: "hamam_sousse" },
	{ label: "Akouda", value: "akouda" },
	{ label: "Sousse centre", value: "sousse_centre" },
	{ label: "Sidi Abdelhamid", value: "sidi_abdelhamid" },
	{ label: "Sousse Sud (Zaouia, Ksiba, Thrayet)", value: "sousse_sud" },
	{ label: "Sousse Riadh (Riadh, Zouhour)", value: "sousse_riadh" },
];

export const genderOptions = [
	{ label: "Homme", value: "Male" },
	{ label: "Femme", value: "Female" },
];

export const professionalSituationOptions = [
	{ label: "Employé", value: "employe" },
	{ label: "Étudiant", value: "etudiant" },
	{ label: "Profession libérale", value: "profession_libérale" },
	{ label: "En recherche d'emploi", value: "en_recherche_emploi" },
	{ label: "Entrepreneur", value: "entrepreneur" },
];

export const educationLevelOptions = [
	{ label: "Primaire", value: "primaire" },
	{ label: "Secondaire", value: "secondaire" },
	{ label: "Baccalauréat", value: "baccalaureat" },
	{ label: "Licence", value: "licence" },
	{ label: "Master", value: "master" },
	{ label: "Doctorat", value: "doctorat" },
	{ label: "Autre (veuillez préciser)", value: "education_level_other" },
];

export default Step2;
